import { EnumFilterFields, EnumDeviceStateFields, RawMessagesFiltersQuery, initDevicesStatesFilters, LoRaWANRawMessagesFilters } from 'models';
import { ExtendedIncidentsTypesEnumModel, IncidentsTypesEnumModel, StatisticMetricEnum } from 'models/device-monitoring';
import { createPath } from 'utils/routing/query';
import { GetStatParams } from 'models/device-statistic';
import { isNil } from 'utils/models';

export * from './caller';
export * from './parking';
export * from './base-station';
export * from './user-management';
export * from './profile';
export * from './device-management';
export * from './rabbit';

export const deviceIncidentsHistoryPath = '/devices/incidents-history';
export const apiCallerHistoryPath = '/api/http/history';
export const apiCallerRoutersPath = '/api/http/routers';
export const deviceStatesPath = '/devices/states';
export const deviceLedPath = '/devices/led';
export const apiRawMessagesPath = (filters?: Partial<RawMessagesFiltersQuery>) => createPath({
  pathname: '/api/raw-messages',
  hashParams: filters,
});
export const apiLorawanRawMessagesPath = (filters?: Partial<LoRaWANRawMessagesFilters>) => createPath({
  pathname: '/api/lorawan-messages',
  hashParams: filters,
});

export const projectsPath = '/settings/projects';
export const getProjectPath = (projectId: number | string) => `${projectsPath}/${projectId}`;

export const provisioningPath = `/devices/provisioning`;
export const provisioningDevicesPath = `${provisioningPath}/devices`;
export const provisioningBoxesPath = `${provisioningPath}/boxes`;
export const provisioningShipmentsPath = `${provisioningPath}/shipments`;
export const provisioningPersonalShipmentPath = (id: string) => `${provisioningShipmentsPath}/${id}`;
export const provisioningPersonalBoxPath = (id: number | string) => `${provisioningBoxesPath}/${id}`;

function addPathParam(filter: number[] | string[], name: string): string[] {
  return filter.length ?
    [`${name}=${filter.join(',')}`] :
    [];
}

const errorsListForFailures = [
  IncidentsTypesEnumModel.HardwareError,
  IncidentsTypesEnumModel.Manual,
  IncidentsTypesEnumModel.AnomalousStatusDuration,
];

const errorsListForFailuresAdmin = [
  ...errorsListForFailures,
  ExtendedIncidentsTypesEnumModel.DaylightSensorError,
  ExtendedIncidentsTypesEnumModel.MagnetometerError,
  ExtendedIncidentsTypesEnumModel.NightLightSensorError,
  ExtendedIncidentsTypesEnumModel.UnexpectedResetError,
  ExtendedIncidentsTypesEnumModel.LowBattery,
  ExtendedIncidentsTypesEnumModel.InductiveSensorError,
  ExtendedIncidentsTypesEnumModel.RadioError,
];

const compileFiltersForStatePage = (filters: Partial<GetStatParams>): string[] => {
  return [
    ...(addPathParam(isNil(filters.owner) ? [] : [filters.owner], EnumDeviceStateFields.owner)),
    ...(addPathParam(filters.zones || [], EnumDeviceStateFields.zones)),
    ...(addPathParam(filters.devices || [], EnumDeviceStateFields.devices)),
    ...(addPathParam(filters.groups || [], EnumDeviceStateFields.groups)),
    ...(addPathParam(filters.projects ? filters.projects : [], EnumDeviceStateFields.projects)),
  ];
};

export const urlToFailuresTable = (filters: Partial<GetStatParams>): string => {
  return [
    `${deviceIncidentsHistoryPath}#?`,
    ...(addPathParam(filters.zones || [], EnumFilterFields.zones)),
    ...(addPathParam(filters.devices || [], EnumFilterFields.devices)),
    ...(addPathParam(filters.groups || [], EnumFilterFields.groups)),
    ...(filters.timeFrom ? addPathParam([filters.timeFrom.toISOString()], EnumFilterFields.appearingTimeFrom) : []),
    ...(filters.timeTo ? addPathParam([filters.timeTo.toISOString()], EnumFilterFields.appearingTimeTo) : []),
    ...(addPathParam(filters.projects ? filters.projects : [], EnumFilterFields.projects)),
  ].join('&');
};

export const urlToFailuresInTheField = (filters: Partial<GetStatParams>, admin?: boolean): string => {
  const errors = admin ? errorsListForFailuresAdmin : errorsListForFailures;
  return [
    `${deviceStatesPath}#?${EnumDeviceStateFields.incidentTypes}=${errors.join(',')}`,
    ...(compileFiltersForStatePage(filters))
  ].join('&');
};

export const urlToSilentsInTheField = (filters: Partial<GetStatParams>): string => {
  return [
    `${deviceStatesPath}#?${EnumDeviceStateFields.incidentTypes}=${IncidentsTypesEnumModel.LastMessageTimeError},${IncidentsTypesEnumModel.PositionedButNotCalibrated}`,
    ...(compileFiltersForStatePage(filters))
  ].join('&');
};

export const urlToTotalFailures = (filters: Partial<GetStatParams>): string => {
  return [
    `${deviceStatesPath}#?${EnumDeviceStateFields.severity}=${initDevicesStatesFilters.severity.join(',')}`,
    ...(compileFiltersForStatePage(filters))
  ].join('&');
};

export const urlToInactive = (filters: Partial<GetStatParams>): string => {
  return [
    `${deviceStatesPath}#?${EnumDeviceStateFields.incidentTypes}=${StatisticMetricEnum.PositionedButInactive}`,
    ...(compileFiltersForStatePage(filters))
  ].join('&');
};
