import { useAuthUserSelector } from 'hooks';
import { useGroupedFilters } from './useGroupedFilters';
// components
import { GroupSelectControl, LevelsFilterSelect, ProjectSelectControl, ZoneSelectControl } from 'components/Controls';
import OwnersSelect from 'components/Controls/OwnersSelect/SingleSelectControl';
import { filterGroups, onChangeLevels } from './utils';

export interface Props {
  // Owner selector props
  owner?: number;
  handleSelectOwner: (value?: number) => void;
  // Project selector props
  isProjectShow?: boolean;
  projects?: number[] | null;
  handleSelectProjects?: (value: number[]) => void;
  // Zones selector props
  isZoneShow?: boolean;
  zones?: number[];
  handleSelectZones?: (value: number[]) => void;
  // Levels selector props
  isLevelsShow?: boolean;
  levels?: number[];
  handleSelectLevels?: (value: number[]) => void;
  // Groups  selector props
  isGroupsShow?: boolean;
  groups?: number[];
  handleSelectGroups?: (value: number[]) => void;
}

const defaultFuncReturn = () => [];

const GroupedFilters = (props: Props): JSX.Element => {
  const onProjectsSelect = props.handleSelectProjects ?? defaultFuncReturn;
  const onZonesSelect = props.handleSelectZones ?? defaultFuncReturn;

  const { isAdmin } = useAuthUserSelector();
  const {
    setZonesCache,
    filterZone,
    filteredProjects,
    handleSetProjectsZones,
    handleSetZonesProjects,
  } = useGroupedFilters(props);

  return (
    <>
      { isAdmin && (
        <OwnersSelect
          isClearable
          selected={ props.owner }
          changeHandler={ props.handleSelectOwner }
        />
      ) }
      { props.isProjectShow && (
        <ProjectSelectControl
          isMulti
          isClearable
          selected={ props.projects || [] }
          onChange={ (v, action) => {
            if (action?.action === 'clear') {
              onZonesSelect([]);
              setZonesCache([]);
            }
            onProjectsSelect(v);
          } }
          onMenuOpen={ () => {
            setZonesCache(props.zones);
          } }
          onBlur={ handleSetProjectsZones }
          filter={ project => filteredProjects.includes(project.id) }
        />
      ) }
      { props.isZoneShow && (
        <ZoneSelectControl
          isMulti
          isClearable
          filter={ filterZone }
          selected={ props.zones ?? [] }
          onBlur={ handleSetZonesProjects }
          onChange={ onZonesSelect }
        />
      ) }
      { props.isLevelsShow && (
        <LevelsFilterSelect
          isMulti
          isClearable
          label="Level"
          zoneIds={ props.zones ?? [] }
          selected={ props.levels ?? [] }
          onChange={ onChangeLevels(props) }
        />
      ) }
      { props.isGroupsShow && (
        <GroupSelectControl
          isMulti
          isClearable
          zoneIds={ props.zones ?? [] }
          selected={ props.groups ?? [] }
          onChange={ props.handleSelectGroups || defaultFuncReturn }
          filter={ filterGroups(props)}
        />
      ) }
    </>
  );
};

export default GroupedFilters;
