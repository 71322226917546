import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { DevicesStatesFiltersFields } from 'models';
import { initialState } from './utils';

// components
import { CustomIdSelect, IncidentErrorTypeSelect, IncidentReasonSelect, SeverityTypeSelect } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/filters';

interface Props {
  isLoading: boolean;
  filter: DevicesStatesFiltersFields;
  onSave: (filters: DevicesStatesFiltersFields) => void;
  onReset: () => void;
}

export const DeviceStatesTableFilter = ({
  onSave,
  onReset,
  isLoading,
  filter
}: Props): JSX.Element => {
  const classes = useStyles();
  const [state, setState] = useState(filter);

  useDeepCompareEffect(() => {
    setState(filter);
  }, [filter]);

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={filter}
      onSave={() => { onSave(state); }}
      onReset={() => { onReset(); setState(initialState); }}
    >
      <div className={ classes.gridRoot }>
        <GroupedFilters
          owner={ state.owner }
          handleSelectOwner={ owner => setState(prev => ({ ...prev, owner })) }
          isProjectShow
          projects={ state.projects }
          handleSelectProjects={ projects => setState(prev => ({ ...prev, projects })) }
          isZoneShow
          zones={ state.zones }
          handleSelectZones={ zones => setState(prev => ({ ...prev, zones })) }
          isLevelsShow
          levels={ state.levels }
          handleSelectLevels={ levels => setState(prev => ({ ...prev, levels })) }
          isGroupsShow
          groups={ state.groups }
          handleSelectGroups={ groups => setState(prev => ({ ...prev, groups })) }
        />
        <CustomIdSelect
          selected={ state.devices }
          changeHandler={ devices => setState({ ...state, devices }) }
          name="Device"
          type="hex"
        />
        <IncidentErrorTypeSelect
          isMulti
          isClearable
          label="Error Types"
          schema="deviceState"
          selected={ state.incidentTypes }
          onChange={ incidentTypes => setState({ ...state, incidentTypes }) }
        />
        <IncidentReasonSelect
          isMulti
          isClearable
          label="Reasons"
          schema="deviceState"
          selected={ state.reason ?? [] }
          onChange={ reason => setState({ ...state, reason }) }
        />
        <SeverityTypeSelect
          isMulti
          isClearable
          selected={ state.severity }
          onChange={ severity => setState({ ...state, severity }) }
        />
      </div>
    </FilterButton>
  );
};
