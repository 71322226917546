import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import moment from 'moment';
import { GetStatParams } from 'models/device-statistic';
import { State as UserState } from 'reducers/user-profile';
import { useProjectsDictionarySelector } from 'hooks/user-managment';
import { useLocationItems } from 'hooks/location';
import { UrlItems } from 'utils/routing/query';
import { Parser } from 'utils/routing/parser';
import { Project } from 'models/user-management';
import { initStatFilters } from '../models';

function getFiltersFromUrl(urlState: UrlItems): GetStatParams {
  const parser = new Parser(urlState as GetStatParams);

  return {
    owner: parser.asNumber('owner') ?? initStatFilters.owner,
    projects: parser.asNumbers('projects') ?? initStatFilters.projects,
    zones: parser.asNumbers('zones') ?? initStatFilters.zones,
    devices: parser.asStrings('devices') ?? initStatFilters.devices,
    levels: parser.asNumbers('levels') ?? initStatFilters.levels,
    groups: parser.asNumbers('groups') ?? initStatFilters.groups,
    timeFrom: parser.asDate('timeFrom')
      ? moment(parser.asDate('timeFrom')).utc()
      : initStatFilters.timeFrom,
    timeTo: parser.asDate('timeTo')
      ? moment(parser.asDate('timeTo')).utc()
      : initStatFilters.timeTo,
  };
}

const getDefaultParams = (items: UrlItems, user: UserState, projects: Project[]) => {
  const { owner, ...defaultFilter } = getFiltersFromUrl(items);
  const userOwner = Number(user.data.user_group);
  // logic -> https://nwaveio.atlassian.net/browse/BNIV-2426
  // admin
  if (user.isAdmin) {
    if (!owner && defaultFilter.projects.length === 0) {
      const ownerProjects = projects.filter(p => Number(p.owner_id) === userOwner).map(p => p.id);
      return { ...defaultFilter, owner: userOwner, projects: ownerProjects };
    }
    if (owner && defaultFilter.projects.length === 0) {
      const ownerProjects = projects.filter(p => Number(p.owner_id) === owner).map(p => p.id);
      return { ...defaultFilter, owner: owner, projects: ownerProjects };
    }
    if (owner && defaultFilter.projects.length !== 0) {
      return { ...defaultFilter, owner };
    }
    if (defaultFilter.projects.length !== 0) {
      return { ...defaultFilter };
    }
  }
  // user
  if (defaultFilter.projects.length === 0) {
    const ownerProjects = projects.filter(p => Number(p.owner_id) === userOwner).map(p => p.id);
    return { ...defaultFilter, projects: ownerProjects };
  }
  if (defaultFilter.projects.length !== 0) {
    return { ...defaultFilter };
  }
  // default
  return defaultFilter ;
};

export const useDevicesHealthParams = (user: UserState) => {
  const { items, setItems } = useLocationItems();
  const { isLoading, projects } = useProjectsDictionarySelector();
  const [params, setParams] = useState<GetStatParams>(initStatFilters);

  // startup: set url and default params
  useDeepCompareEffect(() => {
    if (!isLoading && projects.length > 0) {
      const defaultParams = getDefaultParams(items, user, projects);
      setParams(defaultParams);
      setItems(defaultParams);
    }
  }, [isLoading, {}]);

  return {
    params,
    applyParams: (newParams: GetStatParams) => { setParams(newParams); setItems(newParams); },
    resetParams: () => {
      setParams(getDefaultParams({}, user, projects));
      setItems(getDefaultParams({}, user, projects));
    },
    isFilterDataLoading: isLoading
  };
};