import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { urlToFailuresTable, urlToFailuresInTheField, urlToSilentsInTheField, urlToTotalFailures, urlToInactive } from '../../routing/paths';
import { initalChartData } from 'components/Charts/ChartsOptions/CommonOptions';
import { useDevicesHealthStat } from './hooks/useDevicesHealthStat';
import { useDevicesHealthParams } from './hooks/useDevicesHealthParams';
import { useAuthUserSelector } from 'hooks';

// components
import { PageLoader } from 'components/Loaders';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { ReplacementsChart, ConnectivityChart, BrokenDevicesChart, FailureChart } from 'components/Charts';
import { Skeleton } from '@mui/lab';
import { DevicesHealthFilter } from './DevicesHealthFilter';

// styles
import useStyles from './DevicesHealthStyles';
import contentStyles from 'styles/page';

export const DevicesHealthPage = (): JSX.Element => {
  const classes = { ...contentStyles(), ...useStyles() };
  const user = useAuthUserSelector();
  const { applyParams, resetParams , params, isFilterDataLoading } = useDevicesHealthParams(user);
  const statisctics = useDevicesHealthStat({ params, enabled: !isFilterDataLoading });
  const initChart = initalChartData(params.timeFrom, params.timeTo);

  if (isFilterDataLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Devices Health</title>
      </Helmet>

      <div className={ classes.toolbar }>
        <DevicesHealthFilter
          currentFilters={ params }
          isLoading={ statisctics.isFetching }
          onSave={ applyParams }
          onReset={ resetParams }
        />
      </div>

      <Box display="flex" mt={ 1 }>
        <Grid container={ true } spacing={ 1 } alignContent="flex-start">
          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { statisctics.isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <ConnectivityChart
                        connectivity={ statisctics.connectivity.log ?? [] }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { statisctics.isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        Connectivity: <span className={ classes.count2 }>{ `${statisctics.connectivity.total}%` }</span>
                      </Typography>
                      <Typography display="inline" className={ classes.marginRight }>
                        Installed Devices: <span className={ classes.count }>{ statisctics.installed }</span>
                      </Typography>
                      <Typography display="inline">
                        Empty Spaces: <span className={ classes.count }>{ statisctics.empty }</span>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { statisctics.isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <ReplacementsChart
                        data={ statisctics.replacement.log }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { statisctics.isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        Total replacements: <span className={ classes.count3 }>{ statisctics.replacement.log.reduce((prev, curr) => prev + curr.count, 0) }</span>
                      </Typography>
                      <Typography display="inline">
                        For selected period: <span className={ classes.count3 }>{ statisctics.replacement.total }</span>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { statisctics.isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <BrokenDevicesChart
                        damages={ statisctics.damage.log }
                        silents={ statisctics.silents.log }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { statisctics.isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        <span>Failures in the field: </span>
                        <Link
                          to={ urlToFailuresInTheField(params, user.isAdmin) }
                          className={ classes.count4 }
                        >
                          { statisctics.damage.total }
                        </Link>
                      </Typography>
                      <Typography display="inline" className={ classes.marginRight }>
                        <span>Silent devices: </span>
                        <Link
                          to={ urlToSilentsInTheField(params) }
                          className={ classes.count2 }
                        >
                          { statisctics.silents.total }
                        </Link>
                      </Typography>
                      <Typography display="inline" mr="10px">
                        <span>Inactive: </span>
                        <Link
                          to={ urlToInactive(params) }
                          className={ classes.count }
                        >
                          { statisctics.inactive.total }
                        </Link>
                      </Typography>
                      <Typography display="inline">
                        <span>Total: </span>
                        <Link
                          to={ urlToTotalFailures(params) }
                          className={ classes.count }
                        >
                          { statisctics.damage.total + statisctics.silents.total + statisctics.inactive.total }
                        </Link>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { statisctics.isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <FailureChart
                        failures={ statisctics.incidents.log }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { statisctics.isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        <span>Total failures: </span>
                        { statisctics.incidents.total }
                      </Typography>
                      <Typography display="inline">
                        <span>For selected period: </span>
                        <Link
                          to={ urlToFailuresTable(params) }
                          className={ classes.count4 }
                        >
                          { statisctics.incidents.log.reduce((prev, curr) => prev + curr.total, 0) }
                        </Link>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
