import { call, put, takeEvery } from 'redux-saga/effects';
import { NotifyError } from 'actions/notifier';
import { GetStatParams } from 'models/device-statistic';
import * as zoneActions from 'actions/device-management/zones';
import * as dmClient from 'clients/device-management';
import { fetchZonesAvgConn } from 'clients/connectivity';
import { ApiResponse } from 'models';
import * as dmModels from 'models/device-management';
import { GetDmStatParams, ResponseReason, Statistic } from 'models/device-management';
import { ZoneConnectivity } from 'models/connectivity/zoneConnectivity';

function* fetchZoneStatistics(action: zoneActions.GetZoneStatistics) {
  const dmStatParams: GetDmStatParams = {
    ...action.filters,
    devices: [],
    projects: [],
    metrics: undefined,
  };
  const response: dmModels.ResponseStatistic = yield call(
    dmClient.fetchAllStatistics,
    dmStatParams,
  );
  const connectivity: ApiResponse<ZoneConnectivity[]> = yield call(fetchZonesAvgConn, action.filters as GetStatParams);
  if (response.reason === ResponseReason.Ok && response.data && connectivity.reason === ResponseReason.Ok && connectivity.data) {
    const data = {
      statAll: response.data as Statistic,
      connectivity: connectivity.data,
    };
    yield put(zoneActions.DoGetZoneStatisticsSuccess(data));
  } else {
    yield put(zoneActions.DoGetZoneStatisticsFailed());

    if (response.reason !== ResponseReason.Ok) {
      yield put(NotifyError(`Error while fetching zone statistics: ${ response.message || 'Server Error' }`));
    }

    if (connectivity.reason !== ResponseReason.Ok) {
      yield put(NotifyError(`Error while fetching zone connectivity: ${ connectivity.message || 'Server Error' }`));
    }
  }
}

export const deviceManagementZonesSagas = [
  takeEvery(zoneActions.FETCH_ZONE_STATISTICS, fetchZoneStatistics),
];
