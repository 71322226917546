import { API } from 'aws-amplify';
import { staticEnv } from 'env';

import {
  ApiGConfig,
  ApiResponse,
  ApiResponseWithTotal,
  IncidentsFiltersState,
  ResolvedStatuses,
  DevicesStatesFiltersQuery,
} from 'models';
import { ActivationStatuses, DamagedStatuses } from 'models/common';
import { GetStatParams } from 'models/device-statistic';
import { errorHandler, formatQuery } from './utils';
import {
  ResponseIncidentListBody,
  StatisticMetricHistoryEnum,
  ResponseStatisticBody,
  DeviceStatesV2
} from 'models/device-monitoring';
import { StatisticMetricEnum } from 'models/device-monitoring/statisticMetricEnum';

const urls = {
  incidents: '/monitoring/incidents',
  devicesPivot: '/monitoring/devices/pivot',
  devicesStates: '/monitoring/devices/states',
  statistic: '/monitoring/statistic',
  statisticLog: '/monitoring/statistic/history',
  enumSchema: '/monitoring/enum_schema'
};

function setupIncidentsFilters(filters: IncidentsFiltersState, download: boolean) {
  const damageStatusKey = filters.damagedStatuses as keyof typeof DamagedStatuses;
  const activationStatusKey = filters.activationStatuses as keyof typeof ActivationStatuses;
  const resolutionStatusKey = filters.resolvingStatuses as keyof typeof ResolvedStatuses;

  return {
    ...(!download && {
      limit: filters.limit,
      offset: filters.offset
    }),
    owner_id: filters.owner,
    project_id: filters.projects,
    zone_id: filters.zones,
    level_id: filters.levels,
    group_id: filters.groups,
    device_id: filters.devices,
    error_type: filters.problemTypes,
    resolving_type: filters.resolvingTypes,
    reason: filters.reason,

    damaged: filters.damagedStatuses
      ? DamagedStatuses[damageStatusKey] === DamagedStatuses.damaged
      : undefined,

    active: filters.activationStatuses
      ? ActivationStatuses[activationStatusKey] === ActivationStatuses.active
      : undefined,

    is_resolved: filters.resolvingStatuses
      ? ResolvedStatuses[resolutionStatusKey] === ResolvedStatuses.resolved
      : undefined,

    resolved_time_from: filters.resolvingTimeFrom?.toISOString(),
    resolved_time_to: filters.resolvingTimeTo?.toISOString(),
    created_time_from: filters.appearingTimeFrom?.toISOString(),
    created_time_to: filters.appearingTimeTo?.toISOString(),
  };
}

function normalizeDevicesStatesQuery(query: DevicesStatesFiltersQuery) {
  return {
    owner_id: Number.isInteger(query.owner) ? query.owner : undefined,
    project_id: query.projects,
    zone_id: query.zones,
    level_id: query.levels,
    group_id: query.groups,
    device_id: query.devices,
    network_id: query.networkIds,
    incident_type: query.incidentTypes,
    reason: query.reason,
    severity: query.severity,
    limit: query.limit,
    offset: query.offset
  };
}

export const config: ApiGConfig = {
  name: 'monitoring',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://devmon.prod.api.nwave.io' : 'https://devmon.dev.api.nwave.io',
  swagger: 'https://apidoc.nwave.io/dev_monit/',
};

export const fetchIncidentsData = (filters: IncidentsFiltersState): Promise<ResponseIncidentListBody> => {

  const options = {
    queryStringParameters: formatQuery({
      ...setupIncidentsFilters(filters, false)
    })
  };

  return API
    .get(config.name, urls.incidents, options)
    .catch(errorHandler);
};

export const markIncidentResolved = (id: number, comment: string): Promise<ApiResponse> => {
  return API
    .patch(config.name, urls.incidents + `/${id}`, { body: { is_resolved: true, comment, } })
    .catch(errorHandler);
};

export const markIncidentDamaged = (id: number, _comment: string): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.incidents + `/${id}`, { body: { damaged_status: true, } })
    .catch(errorHandler);
};

export const createDamagedIncident = (deviceId: string, reason: string, comment: string): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.incidents, {
      body: {
        device_id: deviceId,
        comment: comment,
        is_resolved: true,
        damaging: true,
        reason: reason,
        error_type: 'manual',
        error_datetime: (new Date()).toISOString()
      }
    })
    .catch(errorHandler);
};

export const fetchIncidentsReport = (filters: IncidentsFiltersState): Promise<ApiResponse> => {
  const body = {
    response: true,
    responseType: 'text',
    queryStringParameters: formatQuery({
      download: true,
      ...setupIncidentsFilters(filters, true)
    })
  };

  return API
    .get(config.name, urls.incidents, body)
    .catch(errorHandler);
};


export const fetchDeviceStates = (filters: DevicesStatesFiltersQuery): Promise<ApiResponseWithTotal<DeviceStatesV2[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      ...normalizeDevicesStatesQuery(filters)
    })
  };

  return API
    .get(config.name, urls.devicesStates, body)
    .catch(errorHandler);
};

export const fetchDeviceStatesReport = (query: DevicesStatesFiltersQuery): Promise<ApiResponse> => {
  const body = {
    response: true,
    responseType: 'text',
    queryStringParameters: formatQuery({
      download: true,
      ...normalizeDevicesStatesQuery(query)
    })
  };

  return API
    .get(config.name, urls.devicesStates, body)
    .catch(errorHandler);
};

export const fetchIncidentsLog = (params: GetStatParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: {
      // TODO: put it inside `formatQuery` when API supports comma-separated metrics
      metric: [StatisticMetricHistoryEnum.SilentHistory],

      ...formatQuery({
        owner_id: params.owner,
        project_id: params.projects,
        zone_id: params.zones,
        level_id: params.levels,
        group_id: params.groups,
        date_from: params.timeFrom?.toISOString(),
        date_until: params.timeTo?.toISOString()
      }),
    }
  };

  return API
    .get(config.name, urls.statisticLog, body)
    .catch(errorHandler);
};

export const fetchIncidents = (params: GetStatParams): Promise<ResponseStatisticBody> => {
  const body = {
    queryStringParameters: {
      // TODO: put it inside `formatQuery` when API supports comma-separated metrics
      metric: params.metrics ?? [
        StatisticMetricEnum.SilentOk,
        StatisticMetricEnum.FailuresHistory,
        StatisticMetricEnum.TotalFailures,
        StatisticMetricEnum.PositionedButInactive,
      ],

      ...formatQuery({
        owner_id: params.owner,
        project_id: params.projects,
        zone_id: params.zones,
        level_id: params.levels,
        group_id: params.groups,
        date_from: params.timeFrom?.toISOString(),
        date_until: params.timeTo?.toISOString()
      }),
    },
  };

  return API
    .get(config.name, urls.statistic, body)
    .catch(errorHandler);
};

export const fetchEnumSchemas = (): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.enumSchema, {})
    .catch(errorHandler);
};
