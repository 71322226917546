import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { isEqual } from 'lodash';
import { useProjectsDictionarySelector } from 'hooks/user-managment';
import { useZones, useGroups } from 'hooks/device-management';
import { Zone } from 'models/device-management';
import { Project } from 'models/user-management';
import { getZonesFromProjects } from './utils';
import { ExistingZone } from './types';
import { Props } from './GroupedFiltersComponent';

type Model = ExistingZone | Project;

const filterByOwner = (data: Model[], owner?: number) => data
  .filter(el => !owner || el.owner_id === owner)
  .map(el => el.id);

export const useGroupedFilters = (props: Props) => {
  const {
    owner,
    zones,
    groups,
    projects,
    handleSelectProjects,
    handleSelectZones,
    handleSelectGroups,
  } = props;

  const selectedZonesSet = new Set(zones);
  const { zones: allZones } = useZones({ params: {} });
  const { groups: allGroups, isLoading: isGroupsLoading } = useGroups({ params: { zones }, enabled: !!zones });
  const { projects: allProjects } = useProjectsDictionarySelector();

  /*  when user choose zones, and after that choose owner/project,
      delete zone id with non choosen owner/project
  */
  const getCorrectZones = (zoneForDelete: ExistingZone[], selectedZonesSet: Set<number>) => {
    for (let i = 0; i < zoneForDelete?.length; i++) {
      selectedZonesSet.has(zoneForDelete[i].id) && selectedZonesSet.delete(zoneForDelete[i].id);
    }
    return selectedZonesSet;
  };

  const zoneByNotChoosenOwner = owner ? allZones.filter(zone => zone.owner_id !== owner) : [];

  // @TODO refator this
  // this hook is responsible for the correct display view of zones list,
  // depending on the selected projects or owner
  useDeepCompareEffect(() => {
    if (!!zoneByNotChoosenOwner && selectedZonesSet.size !== 0 && handleSelectZones) {
      const zonesByOwner = getCorrectZones(zoneByNotChoosenOwner, selectedZonesSet);
      if (!isEqual(Array.from(zonesByOwner), Array.from(selectedZonesSet))) { // don't trigger extra re-renders
        handleSelectZones(Array.from(zonesByOwner));
      }
    }
  }, [owner, {}]);

  // in case of change projects - set only new projects zones
  useDeepCompareEffect(() => {
    if (projects?.length && Array.from(selectedZonesSet).length > 0 && handleSelectZones) {
      const zonesByProject = getZonesFromProjects(allZones, projects);

      const projectZones = Array.from(selectedZonesSet).filter(zoneId => zonesByProject.includes(zoneId));
      if (!isEqual(Array.from(projectZones), Array.from(selectedZonesSet))) {
        handleSelectZones(Array.from(selectedZonesSet));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, allZones]);

  // in case of change zones - set only new zones groups
  useDeepCompareEffect(() => {
    if (!handleSelectGroups || isGroupsLoading) {
      return;
    }

    const zonesGroups = allGroups
      .filter(g => groups && groups.includes(g.id))
      .map(g => g.id);

    if (!isEqual(zonesGroups, groups)) {
      handleSelectGroups(zonesGroups);
    }
  }, [selectedZonesSet, isGroupsLoading]);

  // filter zones
  const filterZone = (zone: Zone): boolean => {
    if (owner && owner !== zone.owner_id) {
      return false;
    }
    if (projects?.length && !projects.includes(Number(zone.project_id))) {
      return false;
    }
    return true;
  };

  // filter projects
  const filteredProjects: number[] = filterByOwner(allProjects, owner);

  // https://nwaveio.atlassian.net/browse/BNIV-2029
  // auto select projects for seleted zones
  const handleSetZonesProjects = () => {
    if (handleSelectProjects && zones && zones.length > 0) {
      const selectedProjects = allZones.filter(z => zones.includes(z.id)).map(z => z.project_id);
      if (projects) {
        selectedProjects.push(...projects);
      }
      handleSelectProjects([...new Set(selectedProjects)]);
    }
  };

  // auto select zones for seleted projects
  // @TODO https://nwaveio.atlassian.net/browse/BNIV-2038 it is hot fix, need to refactor GroupedFilters with on blur
  const [zonesCache, setZonesCache] = useState<number[] | undefined>(zones);
  const handleSetProjectsZones = () => {
    const currentZones = [...new Set([...(zones || []), ...(zonesCache || [])])];
    const zonesIds = allZones.filter(z => currentZones.includes(z.id) && projects?.includes(z.project_id)).map(z => z.id);
    handleSelectZones && handleSelectZones(zonesIds);
  };

  return {
    zonesCache,
    setZonesCache,
    filteredProjects,
    filterZone,
    handleSetZonesProjects,
    handleSetProjectsZones,
  };
};