import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { Auth } from 'aws-amplify';

import { staticEnv } from 'env';
import { ApiResponse, ReasonEnum } from 'models';
import { isNil } from 'utils/models';

export enum Messages {
  ServerError = 'Server Error',
  NotAuthenticated = 'not authenticated',
}

interface ApiError extends Error {
  response?: {
    data?: {
      message: string;
      reason: ReasonEnum;
    };
    status?: number;
  };
}

const TESTCAFE_ERROR_SUBSTRINGS = [
  // See the full stack trace in BNIV-638
  [
    'TypeError: Illegal invocation',
    'hammerhead',
    'HTMLAnchorElement.getter',
    'new Promise',
  ],
];

export function isTestCafeError(error: string | Error): boolean {
  if (typeof error !== 'object') {
    return false;
  }

  return TESTCAFE_ERROR_SUBSTRINGS.some(substrings => {
    return substrings.every(substring => error.stack?.includes(substring));
  });
}

/**
 * Handling errors messages from api
 *
 * If there is empty `error.response` it means that its cors error - just show `Server Error`
 * If there is existing data in `error.response.data` it means that server has an error with message
 * But if server doesn't returns any messages - just show `Server Error`
 * @param {object} error
 */
export const errorHandler = (error: string | ApiError): ApiResponse => {

  if (staticEnv.AUTOTEST) {
    if (typeof error === 'string') {
      // eslint-disable-next-line no-console
      console.log('[Error trace]\nMessage = ', error);
    } else {
      // eslint-disable-next-line no-console
      console.log('[Error trace]\nMessage = ', error.message, '\nStack = ', error.stack);
    }
  }

  if (typeof error !== 'string' && error.message === 'Network Error') {
    return {
      message: `Connection issues: ${error.message}`,
      reason: 'server_error',
    };
  }

  if (error === Messages.NotAuthenticated) {
    Auth.signOut(); // eslint-disable-line @typescript-eslint/no-floating-promises
    return { reason: ReasonEnum.Restricted, message: 'You are not authenticated' };
  }

  catchAxiosError(error);

  if (typeof error === 'object' && error?.response?.data?.message) {
    if (error.response.data.reason === ReasonEnum.ServerError) {
      Sentry.captureMessage(JSON.stringify(error?.response));
    } else {
      Sentry.addBreadcrumb({
        category: 'fetch',
        data: error.response,
        message: `Api fetch error code: ${error.response?.status}`,
        level: Sentry.Severity.Info
      });
    }

    return error.response.data;
  }

  Sentry.captureMessage(JSON.stringify({
    message: Messages.ServerError,
    error: JSON.stringify(error)
  }));

  if (staticEnv.AUTOTEST && isTestCafeError(error)) {
    const message = typeof error === 'object' ? error.message : error;

    return {
      message: `${Messages.ServerError} (TestCafe issue: "${message}")`,
      reason: 'server_error',
    };
  }

  let message = Messages.ServerError as string;
  if (typeof error === 'object' && 'detail' in (error.response?.data ?? {})) {
    message = (error.response?.data as unknown as {'detail': string}).detail;
  }
  return {
    message: message,
    reason: 'server_error'
  };
};


const catchAxiosError = (error: unknown): void => {
  if (typeof error !== 'object' || !((error as AxiosError)?.isAxiosError)) {
    return;
  }

  const axiosError = error as AxiosError;
  Sentry.addBreadcrumb({
    category: 'request',
    data: {
      url: axiosError.config.url,
      method: axiosError.config.method,
      params: axiosError.config.params,
      data: axiosError.config.data,
      requestStatus: axiosError.request.status,
      requestData: axiosError.config.data,
      responseStatus: axiosError.response?.status,
      responseData: JSON.stringify(axiosError.response?.data),
    },
    message: `Request details`,
    level: Sentry.Severity.Debug
  });
};

export type Primitive = boolean | number | string | Date;

interface RawQuery {
  [key: string]: Array<Primitive> | Primitive | null | undefined;
}

interface FormattedQuery {
  [key: string]: Primitive | Primitive[];
}

interface Options {
  stringifyArray?: boolean;
}

/**
 * Formats the parameters to be sent via `API` from `aws-amplify`.
 *
 * Omits parameters which equal to `undefined`, `null`, and `[]`.
 *
 * Unlike `buildUrlParams`, it preserves parameters with `''` (empty string)
 * since some of our APIs require sending parameters even if their values are empty.
 * `''` would be the only way to do that, an escape hatch.
 */
export function formatQuery(
  query: RawQuery,
  { stringifyArray = true }: Options = {}
): FormattedQuery {
  return Object.fromEntries(
    Object.entries(query).flatMap(([key, value]) => {
      if (isNil(value)) {
        return [];
      }

      if (Array.isArray(value)) {
        if (value.length === 0) {
          return [];
        }

        if (stringifyArray) {
          return [[key, value.join(',')]];
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return [[key, value as any]];
      }

      if (value instanceof Date) {
        return [[key, value.toJSON()]];
      }

      return [[key, value]];
    }),
  );
}
