import { PositionsFilterFields } from 'models/device-management/filters';
// components
import Grid from '@mui/material/Grid';
import { SingleSelectControl, LabelSelectControl } from 'components/Controls';
import CustomIdSelect from 'components/Controls/CustomIdSelect/CustomIdSelectComponent';
import { NetworkIdSelect } from 'components/Controls/NetworkIdSelect/NetworkIdSelectComponent';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';

// styles
import useStyles from 'styles/filters';

interface Props {
  state: PositionsFilterFields;
  setState: (filters: PositionsFilterFields | ((filters: PositionsFilterFields) => PositionsFilterFields)) => void;
}

const PositionTableFilter = (props: Props): JSX.Element => {
  const { state, setState } = props;
  const classes = useStyles();

  const bindingOptions = [{ label: 'Bound', value: 'bound' }, { label: 'Not Bound', value: 'not_bound' }];

  const handleSelectBindingStatus = (bindingStatus: string | undefined) => setState({ ...state, bindingStatus });
  const handleSelectLabels = (labelIds: number[]) => setState({ ...state, labelIds });

  return (
    <form autoComplete="off" className={ classes.gridRoot }>
      <GroupedFilters
        owner={ state.owner }
        handleSelectOwner={ owner => setState(prev => ({ ...prev, owner })) }
        isProjectShow
        projects={ state.projects }
        handleSelectProjects={ projects => setState(prev => ({ ...prev, projects })) }
        isZoneShow
        zones={ state.zones }
        handleSelectZones={ zones => setState(prev => ({ ...prev, zones })) }
        isLevelsShow
        levels={ state.levels }
        handleSelectLevels={ levels => setState(prev => ({ ...prev, levels })) }
        isGroupsShow
        groups={ state.groupIds }
        handleSelectGroups={ groupIds => setState(prev => ({ ...prev, groupIds })) }
      />

      <Grid>
        <LabelSelectControl
          isMulti
          isClearable
          ownerId={ state.owner }
          selected={ state.labelIds ?? [] }
          onChange={ handleSelectLabels }
        />
      </Grid>
      <Grid>
        <CustomIdSelect
          selected={ state.groupInnerIds ?? [] }
          changeHandler={ (groupInnerIds) => setState({ ...state, groupInnerIds }) }
          type="number"
          name="Inner group"
        />
      </Grid>
      <Grid>
        <NetworkIdSelect
          selected={ state.networkIds ?? [] }
          changeHandler={ (networks: string[]) => setState({ ...state, networkIds: networks }) }
        />
      </Grid>
      <Grid>
        <CustomIdSelect
          name="Custom"
          selected={ state.customIds ?? [] }
          changeHandler={ customIds => setState({ ...state, customIds }) }
          type="string"
        />
      </Grid>
      <Grid>
        <SingleSelectControl
          selected={ state.bindingStatus }
          label="Binding Status"
          values={ bindingOptions }
          changeHandler={ handleSelectBindingStatus }
          name="bound"
          isClearable
        />
      </Grid>
    </form>
  );
};

export default PositionTableFilter;
