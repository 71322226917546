import { Action, SET_INCIDENTS_FILTERS, SET_INCIDENTS_LIMIT_OFFSET, UPDATE_INCIDENTS_FILTERS_FROM_URL } from 'actions/http-incidents';
import { DEFAULT_LIMIT, IncidentsFiltersFields, EnumFilterFields as Fields } from 'models';
import { getUrlItems, convertParamToMoment, UrlItems } from 'utils/routing/query';
import { Incident } from 'models/device-monitoring';
import * as Actions from 'actions/http-incidents';
import { keyBy } from 'utils/ds/array';
import { stringifyObject } from 'utils/ds/object';
import { Defined } from 'utils/models';

import { IncidentsFiltersState } from 'models';
import { parseArrayAsNum } from 'helpers';

type ExistingIncident = Incident & { id: Defined<Incident['id']> };

export interface State {
  data: Incident[];
  total?: number;
  isFetching: boolean;
  csvFetching: boolean;
  filters: IncidentsFiltersState;

  readonly incidentsById: {
    readonly [incidentId: number]: Incident | null | undefined;
  };
  readonly incidentIdsByQuery: {
    readonly [query: string]: Defined<Incident['id']>[] | undefined;
  };
  totalByQuery: {
    [query: string]: number | undefined;
  };
}

export const initIncidentsFilters: IncidentsFiltersFields = {
  zones: [],
  groups: [],
  devices: [],
  problemTypes: [],
  resolvingStatuses: undefined,
  resolvingTypes: undefined,
  damagedStatuses: undefined,
  activationStatuses: undefined,
  reason: undefined,
  resolvingTimeFrom: null,
  resolvingTimeTo: null,
  appearingTimeFrom: null,
  appearingTimeTo: null,
  owner: undefined,
  projects: []
};

export const initState: State = {
  data: [],
  total: 0,
  isFetching: false,
  csvFetching: false,
  filters: {
    ...initIncidentsFilters,
    limit: DEFAULT_LIMIT,
    offset: 0
  },
  incidentsById: {},
  incidentIdsByQuery: {},
  totalByQuery: {},
};

function getFiltersFromUrl(urlState: UrlItems): IncidentsFiltersState {
  return {
    owner: urlState[Fields.owner] ? Number(urlState[Fields.owner]) : undefined,
    zones: urlState[Fields.zones] ? parseArrayAsNum(urlState[Fields.zones]) : [],
    projects: urlState[Fields.projects] ? parseArrayAsNum(urlState[Fields.projects]) : [],
    groups: urlState[Fields.groups] ? parseArrayAsNum(urlState[Fields.groups]) : [],
    levels: urlState[Fields.levels] ? parseArrayAsNum(urlState[Fields.levels]) : [],
    devices: urlState[Fields.devices] ? [].concat(urlState[Fields.devices]) : [],
    problemTypes: urlState[Fields.problemTypes] ? [].concat(urlState[Fields.problemTypes]) : [],
    resolvingStatuses: urlState[Fields.resolvingStatuses] ? urlState[Fields.resolvingStatuses] : undefined,
    resolvingTypes: urlState[Fields.resolvingTypes] ? urlState[Fields.resolvingTypes] : undefined,
    damagedStatuses: urlState[Fields.damagedStatuses] ? urlState[Fields.damagedStatuses] : undefined,
    activationStatuses: urlState[Fields.activationStatuses] ? urlState[Fields.activationStatuses] : undefined,
    reason: urlState[Fields.reason] ? urlState[Fields.reason] : undefined,
    resolvingTimeFrom: urlState[Fields.resolvingTimeFrom] ? convertParamToMoment(urlState[Fields.resolvingTimeFrom]) : null,
    resolvingTimeTo: urlState[Fields.resolvingTimeTo] ? convertParamToMoment(urlState[Fields.resolvingTimeTo]) : null,
    appearingTimeFrom: urlState[Fields.appearingTimeFrom] ? convertParamToMoment(urlState[Fields.appearingTimeFrom]) : null,
    appearingTimeTo: urlState[Fields.appearingTimeTo] ? convertParamToMoment(urlState[Fields.appearingTimeTo]) : null,
    limit: urlState[Fields.limit] ? Number(urlState[Fields.limit]) : DEFAULT_LIMIT,
    offset: urlState[Fields.offset] ? Number(urlState[Fields.offset]) : 0
  };
}

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case Actions.FETCH_INCIDENTS_FAILED:
      return { ...state, isFetching: false, data: [] };
    case Actions.FETCH_INCIDENTS_SUCCESS: {
      const foundIncidents = action.payload.foundIncidents as ExistingIncident[];
      return {
        ...state,
        data: foundIncidents,
        incidentsById: {
          ...state.incidentsById,
          ...keyBy(foundIncidents, 'id'),
        },
        incidentIdsByQuery: {
          ...state.incidentIdsByQuery,
          [stringifyObject(action.payload.filters)]: (foundIncidents).map(i => i.id),
        },
        totalByQuery: {
          ...state.totalByQuery,
          [stringifyObject(action.payload.filters)]: action.payload.total,
        },
        isFetching: false,
        total: action.payload.total,
      };
    }
    case Actions.FETCH_INCIDENTS_START:
      return { ...state, isFetching: true };
    case Actions.MARK_RESOLVED_START:
      return { ...state, isFetching: true };
    case Actions.MARK_RESOLVED_FAILED:
      return { ...state, isFetching: false };
    case Actions.MARK_DAMAGED_START:
      return { ...state, isFetching: true, };
    case Actions.MARK_DAMAGED_SUCCESS:
      return { ...state, isFetching: false };
    case Actions.MARK_DAMAGED_FAILED:
      return { ...state, isFetching: true };
    case Actions.INCIDENTS_FETCH_REPORT_CSV:
      return { ...state, csvFetching: true };
    case Actions.INCIDENTS_FETCH_REPORT_CSV_END:
      return { ...state, csvFetching: false };
    case SET_INCIDENTS_LIMIT_OFFSET:
      return {
        ...state,
        filters: {
          ...state.filters,
          limit: action.limit,
          offset: action.offset
        }
      };
    case SET_INCIDENTS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case UPDATE_INCIDENTS_FILTERS_FROM_URL:
      const urlState = getUrlItems(Object.values(Fields));

      return {
        ...state,
        filters: {
          ...state.filters,
          ...getFiltersFromUrl(urlState),
        }
      };
    case Actions.CREATE_DAMAGED_INCIDENT_START:
      return { ...state, isFetching: true };
    case Actions.CREATE_DAMAGED_INCIDENT_FAILED:
    case Actions.CREATE_DAMAGED_INCIDENT_SUCCESS:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
