import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { GroupsFilterFields, initGroupsFilters } from 'models/device-management/filters';
import { groupTypes as types } from 'models/device-management/dm';

// components
import Grid from '@mui/material/Grid';
import { SingleSelectControl } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/filters';

type Props = {
  applyFilters: (filter: GroupsFilterFields) => void
  filters: GroupsFilterFields
  isFetching: boolean
}

const GroupsFilter = ({ applyFilters, filters, isFetching }: Props): JSX.Element => {
  const classes = useStyles();
  const [state, setState] = useState<GroupsFilterFields>(initGroupsFilters);

  useDeepCompareEffect(() => {
    setState(filters);
  }, [filters]);

  const onSave = () => applyFilters(state);
  const onReset = () => {
    setState(initGroupsFilters);
    applyFilters(initGroupsFilters);
  };

  return (
    <FilterButton
      isLoading={ isFetching }
      filter={ filters }
      onSave={ onSave }
      onReset={ onReset }
    >
      <form autoComplete="off" className={ classes.gridRoot }>
        <GroupedFilters
          owner={ state.owner }
          handleSelectOwner={ owner => setState({ ...state, owner }) }
          isProjectShow
          projects={ state.projects }
          handleSelectProjects={ projects => setState({ ...state, projects }) }
          isZoneShow
          zones={ state.zones || [] }
          handleSelectZones={ zones => setState({ ...state, zones }) }
          isLevelsShow
          levels={ state.levels || [] }
          handleSelectLevels={ levels => setState({ ...state, levels }) }
        />
        <Grid>
          <SingleSelectControl
            isClearable
            label="Group Type"
            name="groupType"
            values={ Object.entries(types).map(el => ({ label: el[1], value: el[0] })) }
            selected={ state.groupType }
            changeHandler={ groupType => setState({ ...state, groupType }) }
          />
        </Grid>
      </form>
    </FilterButton>
  );
};

export default GroupsFilter;
