import { DateType } from '@date-io/type';
import { IncidentReasonEnum, DeviceStateSeverityEnumModel } from './device-monitoring';
import { DEFAULT_LIMIT } from './common';

export interface State {
  trace_id?: string;
  error_datetime: string;
}

export enum ResolvedTypes {
  auto = 'Auto',
  manual = 'Manual'
}

export enum ResolvedStatuses {
  resolved = 'Processed',
  not_resolved = 'Not Processed'
}

export interface DeviceState {
  device_id: string;
  trace_id?: string;
  last_update_time?: Date;
  incidents?: [];
  req_url: string;
  error_type?: string;
  network_id?: string;
  is_resolved: string;
  zone_id?: string;
  group_id?: string;
  resolving_type: string;
  damaged_status: string;
  activation_status: string;
  resolved_time?: Date;
  user_id: string;
  comment?: string;
  created_time?: Date;
  resp_body?: string;
  state?: State;
  error_datetime?: Date;
}

export enum EnumDeviceStateFields {
  owner = 'owner',
  projects = 'projects',
  zones = 'zones',
  levels = 'levels',
  groups = 'groups',
  devices = 'devices',
  incidentTypes = 'incidentTypes',
  severity = 'severity',
  reason = 'reason',
  limit = 'limit',
  offset = 'offset',
  networkIds = 'networkIds',
}

export interface DevicesStatesFiltersFields {
  [EnumDeviceStateFields.owner]?: number;
  [EnumDeviceStateFields.projects]?: number[];
  [EnumDeviceStateFields.zones]: number[];
  [EnumDeviceStateFields.levels]?: number[];
  [EnumDeviceStateFields.groups]: number[];
  [EnumDeviceStateFields.devices]: string[];
  [EnumDeviceStateFields.incidentTypes]: string[];
  [EnumDeviceStateFields.severity]: DeviceStateSeverityEnumModel[];
  [EnumDeviceStateFields.reason]?: string[];
  [EnumDeviceStateFields.networkIds]?: string[];
}

export interface DevicesStatesFiltersQuery extends DevicesStatesFiltersFields {
  limit?: number;
  offset?: number;
}

export const initDevicesStatesFilters: DevicesStatesFiltersFields   = {
  [EnumDeviceStateFields.owner]: undefined,
  [EnumDeviceStateFields.projects]: [],
  [EnumDeviceStateFields.zones]: [],
  [EnumDeviceStateFields.levels]: [],
  [EnumDeviceStateFields.groups]: [],
  [EnumDeviceStateFields.devices]: [],
  [EnumDeviceStateFields.incidentTypes]: [],
  [EnumDeviceStateFields.severity]: [
    DeviceStateSeverityEnumModel.Critical,
    DeviceStateSeverityEnumModel.Info,
    DeviceStateSeverityEnumModel.Warning
  ],
  [EnumDeviceStateFields.reason]: undefined,
  [EnumDeviceStateFields.networkIds]: []
};

export enum EnumFilterFields {
  owner = 'owner',
  projects = 'projects',
  zones = 'zones',
  groups = 'groups',
  levels = 'levels',
  devices = 'devices',
  problemTypes = 'problemTypes',
  resolvingStatuses = 'resolvingStatuses',
  resolvingTypes = 'resolvingTypes',
  damagedStatuses = 'damagedStatuses',
  activationStatuses = 'activationStatuses',
  reason = 'reason',
  resolvingTimeFrom = 'resolvingTimeFrom',
  resolvingTimeTo = 'resolvingTimeTo',
  appearingTimeFrom = 'appearingTimeFrom',
  appearingTimeTo = 'appearingTimeTo',
  limit = 'limit',
  offset = 'offset',
}

export interface IncidentsFiltersFields {
  [EnumFilterFields.owner]?: number;
  [EnumFilterFields.projects]?: number[];
  [EnumFilterFields.zones]: number[];
  [EnumFilterFields.levels]?: number[];
  [EnumFilterFields.groups]: number[];
  [EnumFilterFields.devices]: string[];
  [EnumFilterFields.problemTypes]: string[];
  [EnumFilterFields.resolvingTimeFrom]: DateType | null;
  [EnumFilterFields.resolvingTimeTo]: DateType | null;
  [EnumFilterFields.appearingTimeFrom]: DateType | null;
  [EnumFilterFields.appearingTimeTo]: DateType | null;
  [EnumFilterFields.resolvingStatuses]?: string;
  [EnumFilterFields.resolvingTypes]?: string;
  [EnumFilterFields.damagedStatuses]?: string;
  [EnumFilterFields.activationStatuses]?: string;
  [EnumFilterFields.reason]?: string;
}

export interface IncidentsFiltersState extends IncidentsFiltersFields {
  limit?: number;
  offset?: number;
}

export const DamageReasons = {
  [IncidentReasonEnum.BrokenCasing]: 'Case Damage',
  [IncidentReasonEnum.HiddenProblem]: 'Hidden Problem',
  [IncidentReasonEnum.Missing]: 'Missed',
  [IncidentReasonEnum.NotConfirmed]: 'Not Confirmed',
  [IncidentReasonEnum.NotSpecified]: 'Not Specified',
  [IncidentReasonEnum.NoReason]: 'No Reason',
}
