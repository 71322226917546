import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { RootState } from 'reducers';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { DevicesStatesFiltersFields, DevicesStatesFiltersQuery, EnumDeviceStateFields, initDevicesStatesFilters } from 'models';
import { DeviceStateSeverityEnumModel, DeviceStatesV2 } from 'models/device-monitoring';
import { DoFetchReport, DoGetDevicesStatesFilters, DoSetDeviceStatesFilters } from 'actions/devices-states';

interface PageData {
  isLoading: boolean;
  data: DeviceStatesV2[];
  total: number;
}

export const useDeviceStatesByQuery = (params: DevicesStatesFiltersQuery): PageData => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.deviceStates);

  useDeepCompareEffect(() => {
    dispatch(DoSetDeviceStatesFilters(params, false));

    dispatch(DoGetDevicesStatesFilters(params));
  }, [params, dispatch]);

  return {
    isLoading: state.isFetching,
    data: state.data,
    total: state.total ?? 0,
  };
};

interface PageExport {
  isExporting: boolean;
  onExport: () => void;
}
export const useDeviceStatesExport = (filter: DevicesStatesFiltersFields): PageExport => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.deviceStates);

  return {
    isExporting: state.csvFetching,
    onExport: () => dispatch(DoFetchReport(filter)),
  };
};

interface PageParams {
  params: DevicesStatesFiltersQuery
  filter: DevicesStatesFiltersFields;
  updateFilter: (filters: DevicesStatesFiltersFields) => void;
  resetFilter: () => void;
}

export const initialState: DevicesStatesFiltersFields = {
  ...initDevicesStatesFilters,
  [EnumDeviceStateFields.severity]: []
};

export const useDeviceStatesRequestParams = (): PageParams => {
  const location = useLocationItems();
  const urlFilter = buildState(location.items);

  // FILTERS
  const [filter, setFilter] = useState<DevicesStatesFiltersFields>(urlFilter);
  useDeepCompareEffect(() => {
    setFilter(urlFilter);
  }, [urlFilter]);

  // PAGINATION
  const { limit, offset } = usePagination();

  // PARAMS: FILTERS + PAGINATION
  const [params, setParams] = useState<DevicesStatesFiltersQuery>({ ...urlFilter, limit, offset });
  useDeepCompareEffect(() => {
    setParams({
      ...filter,
      limit,
      offset
    });
  }, [filter, limit, offset]);

  return {
    params,
    // @TODO separete filter from this hook
    filter: filter,
    updateFilter: (update: DevicesStatesFiltersFields) => {
      const updatedFilters = update || initialState;
      location.setItems({ ...updatedFilters, limit, offset: 0 });
    },
    resetFilter: () => { location.setItems({ ...initialState, limit, offset: 0 });  },
  };
};

const buildState = (items: UrlItems): DevicesStatesFiltersFields => {
  const parser = new Parser(items as DevicesStatesFiltersFields);
  return {
    ...initialState,
    [EnumDeviceStateFields.owner]: parser.asNumber(EnumDeviceStateFields.owner) ?? initialState[EnumDeviceStateFields.owner],
    [EnumDeviceStateFields.projects]: parser.asNumbers(EnumDeviceStateFields.projects) ?? initialState[EnumDeviceStateFields.projects],
    [EnumDeviceStateFields.zones]: parser.asNumbers(EnumDeviceStateFields.zones) ?? initialState[EnumDeviceStateFields.zones],
    [EnumDeviceStateFields.levels]: parser.asNumbers(EnumDeviceStateFields.levels) ?? initialState[EnumDeviceStateFields.levels],
    [EnumDeviceStateFields.groups]: parser.asNumbers(EnumDeviceStateFields.groups) ?? initialState[EnumDeviceStateFields.groups],
    [EnumDeviceStateFields.devices]: parser.asStrings(EnumDeviceStateFields.devices) ?? initialState[EnumDeviceStateFields.devices],
    [EnumDeviceStateFields.incidentTypes]: parser.asStrings(EnumDeviceStateFields.incidentTypes) ?? initialState[EnumDeviceStateFields.incidentTypes],
    [EnumDeviceStateFields.severity]: parser.asEnums(EnumDeviceStateFields.severity, DeviceStateSeverityEnumModel) ?? initialState[EnumDeviceStateFields.severity],
    [EnumDeviceStateFields.reason]: parser.asStrings(EnumDeviceStateFields.reason) ?? initialState[EnumDeviceStateFields.reason]
  };
};
