import { API } from 'aws-amplify';
import { staticEnv } from 'env';

import { ApiGConfig, ApiResponse } from 'models';
import {
  GetAvgConnectivityPerPositionGroupParams,
  ZoneConnectivity,
  LevelConnectivity,
  GroupConnectivity,
  PositionConnectivity,
  DeviceConnectivity,
} from 'models/connectivity';
import {
  ConnectivityFilters,
  ConnectivityHistoryFilters,
  LevelConnectivityParams,
  PositionConnectivityParams,
} from 'models/connectivity/filters';

import { errorHandler, formatQuery } from './utils';
import { GetStatParams } from 'models/device-statistic';

const urls = {
  zonesAVG: '/zones/connectivity',
  levelsAVG: '/levels/connectivity',
  groupsAVG: '/groups/connectivity',
  positions: '/positions/connectivity',
  zonesAVGlog: '/zones/connectivity/history',
  devicesConnectivity: '/devices/connectivity',
  groupsAVGlog: '/groups/connectivity/history',
  overall: '/overall/connectivity',
  overallHistory: '/overall/connectivity/history',
};

export const config: ApiGConfig = {
  name: 'connectivity',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://connectivity.prod.api.nwave.io' : 'https://connectivity.dev.api.nwave.io',
};

export const fetchZonesAvgConn = (filters: GetStatParams): Promise<ApiResponse<ZoneConnectivity[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      zone_id: filters.zones,
      project_id: filters.projects,
    }, {
      stringifyArray: false
    }),
  };

  return API
    .get(config.name, urls.zonesAVG, body)
    .catch(errorHandler);
};

export const fetchLevelsAvgConn = (filters: LevelConnectivityParams): Promise<ApiResponse<LevelConnectivity[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      level_id: filters.levels,
    }, {
      stringifyArray: false
    }),
  };

  return API
    .get(config.name, urls.levelsAVG, body)
    .catch(errorHandler);
};

export const fetchGroupsAvgConn = (params: GetAvgConnectivityPerPositionGroupParams): Promise<ApiResponse<GroupConnectivity[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      owner_id: params.owner_id,
      group_id: params.groups,
      zone_id: params.zones,
    }, {
      stringifyArray: false
    }),
  };

  return API
    .get(config.name, urls.groupsAVG, body)
    .catch(errorHandler);
};

export const fetchPositionsConn = (params: PositionConnectivityParams): Promise<ApiResponse<PositionConnectivity[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      position_id: params.positions,
    }, {
      stringifyArray: false
    }),
  };

  return API
    .get(config.name, urls.positions, body)
    .catch(errorHandler);
};

export const fetchZonesConnLog = (filter: { zones?: number[]; projects?: number[] }): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: 10000,
      offset: 0,
      zone_id: filter.zones,
      project_id: filter.projects,
    }, {
      stringifyArray: false
    }),
  };
  return API
    .get(config.name, urls.zonesAVGlog, body)
    .catch(errorHandler);
};

export const fetchGroupsConnLog = (groups: number[]): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: 10000,
      offset: 0,
      group_id: groups,
    }, {
      stringifyArray: false
    }),
  };

  return API
    .get(config.name, urls.groupsAVGlog, body)
    .catch(errorHandler);
};

export const fetchZonesOverall = (filters: ConnectivityHistoryFilters): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery(
      { ...filters }, {
        stringifyArray: false
      }),
  };

  return API
    .get(config.name, urls.overall, body)
    .catch(errorHandler);
};

export const fetchZonesOverallHistory = (filters: ConnectivityHistoryFilters, limit = 1000): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      ...filters,
      limit: limit,
      offset: 0,
    }, {
      stringifyArray: false
    }),
  };

  return API
    .get(config.name, urls.overallHistory, body)
    .catch(errorHandler);
};

export const fetchConnectivity = (params: ConnectivityFilters): Promise<ApiResponse<DeviceConnectivity[]>> => {
  const isByDevices = params.devices && params.devices?.length > 0;
  const queryParams = formatQuery({
    ...(!isByDevices && { limit: params.limit }),
    ...(!isByDevices && { offset: params.offset }),
    device_id: params.devices,
    position_id: params.positionId,
    group_id: params.groups,
    zone_id: params.zones,
    position_info: params.positionInfo,
  }, {
    stringifyArray: false
  });
  const body = {
    queryStringParameters: queryParams,
  };

  return API
    .get(config.name, urls.devicesConnectivity, body)
    .catch(errorHandler);
};
